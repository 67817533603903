import _createForOfIteratorHelper from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/createForOfIteratorHelper.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.split.js";
export default function t(t, n) {
  if ("function" === typeof t) {
    return t.apply(this, n);
  } else if ("string" === typeof t) {
    var e = t;

    if ("()" === e.substring(e.length - 2)) {
      e = e.substring(0, e.length - 2);
    }

    var i = e.split(".");
    var o = i.pop();
    var f = window;

    var _iterator = _createForOfIteratorHelper(i),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _t = _step.value;
        f = f[_t];
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return typeof f[o] === "undefined" ? null : f[o].apply(this, n);
  }
}