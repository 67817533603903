import _defineProperty from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions, _mutations;

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.to-string.js";
import ApiService from "@/core/services/api.service";
export var POST = "post";
export var UPLOAD = "upload";
export var DOWNLOAD = "download";
export var GET = "get";
export var QUERY = "query";
export var PUT = "put";
export var PATCH = "patch";
export var DELETE = "delete";
export var PURGE_PREVIOUS = "purgePrevious";
export var SET_TOP_LOADER = "setTopLoader";
export var CLEAR_ERROR = "clearError";
export var UPDATE_ERROR = "updateError";
export var VERIFY_QUOTATION_CUSTOMER = "verifyQuotationCustomer";
export var SET_ERROR = "setError";
export var SET_MESSAGE = "setMessage";
export var SET_DATA = "setData";
export var PURGE_DATA = "purgeData";
var state = {
  errors: {},
  message: {},
  topLoader: {}
};
var getters = {
  topLoader: function topLoader(state) {
    return state.topLoader;
  }
};

var commitErrors = function commitErrors(response) {
  var requestErrors = [];

  if (response && response.status === 422) {
    for (var error in response.data) {
      if (response.data[error]) {
        for (var i = response.data[error].length - 1; i >= 0; i--) {
          requestErrors.push(response.data[error][i]);
        }
      }
    }
  } else if (response && response.status === 401) {
    requestErrors.push(response.data.error);
  } else if (response && response.status === 500) {
    requestErrors.push(response.data.message);
  } else if (response && response.status === 400) {
    requestErrors.push(response.data.message);
  } else if (response && response.status === 404) {
    requestErrors.push(response.data.message);
  } else if (response && response.status === 405) {
    requestErrors.push(response.data.message);
  } else {
    requestErrors.push(response.toString());
  }

  return requestErrors;
};

var actions = (_actions = {}, _defineProperty(_actions, VERIFY_QUOTATION_CUSTOMER, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setCustomHeader(request.token);
    ApiService.post(request.url, request.data).then(function (_ref) {
      var data = _ref.data;
      resolve(data);
    }).catch(function (error) {
      reject(error);
    });
  });
}), _defineProperty(_actions, POST, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.post(request.url, request.data).then(function (_ref2) {
      var data = _ref2.data;

      if (data.message) {
        context.commit(SET_MESSAGE, data.message);
      }

      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, UPLOAD, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.upload(request.url, request.data).then(function (_ref3) {
      var data = _ref3.data;

      if (data.message) {
        context.commit(SET_MESSAGE, data.message);
      }

      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, DOWNLOAD, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.download(request.url, request.data).then(function (response) {
      resolve(response);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, GET, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.get(request.url, request.data).then(function (_ref4) {
      var data = _ref4.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, QUERY, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.query(request.url, request.data).then(function (_ref5) {
      var data = _ref5.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, PUT, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.put(request.url, request.data).then(function (_ref6) {
      var data = _ref6.data;

      if (data.message) {
        context.commit(SET_MESSAGE, data.message);
      }

      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, PATCH, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.patch(request.url, request.data).then(function (_ref7) {
      var data = _ref7.data;

      if (data.message) {
        context.commit(SET_MESSAGE, data.message);
      }

      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, DELETE, function (context, request) {
  return new Promise(function (resolve, reject) {
    ApiService.setHeader();
    ApiService.delete(request.url, request.data).then(function (_ref8) {
      var data = _ref8.data;
      context.commit(SET_DATA, data);
      resolve(data);
    }).catch(function (error) {
      if (error && error.response) {
        context.commit(SET_ERROR, commitErrors(error.response));
        reject(error.response);
      } else {
        context.commit(SET_ERROR, commitErrors(error));
        reject(error);
      }
    });
  });
}), _defineProperty(_actions, PURGE_PREVIOUS, function (context) {
  return new Promise(function (resolve) {
    context.commit(PURGE_DATA);
    resolve(true);
  });
}), _defineProperty(_actions, CLEAR_ERROR, function (context) {
  return new Promise(function (resolve) {
    context.commit(SET_ERROR, []);
    resolve(true);
  });
}), _defineProperty(_actions, UPDATE_ERROR, function (context, error) {
  return new Promise(function (resolve) {
    context.commit(SET_ERROR, commitErrors(error));
    resolve(true);
  });
}), _actions);
var mutations = (_mutations = {}, _defineProperty(_mutations, SET_ERROR, function (state, error) {
  state.errors = error;
}), _defineProperty(_mutations, SET_MESSAGE, function (state, data) {
  state.message = data;
}), _defineProperty(_mutations, SET_TOP_LOADER, function (state, data) {
  state.topLoader = data;
}), _defineProperty(_mutations, SET_DATA, function (state, data) {
  state.data = data;
  state.errors = {};
  state.message = {};
}), _defineProperty(_mutations, PURGE_DATA, function (state) {
  state.data = {};
  state.errors = {};
  state.message = {};
}), _mutations);
export default {
  state: state,
  actions: actions,
  mutations: mutations,
  getters: getters
};