//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { OVERRIDE_LAYOUT_CONFIG } from "@/core/services/store/config.module";
import { vueTopprogress } from "vue-top-progress";
import { TopBarLoaderEventBus } from "@/core/lib/topbar.loader.lib";
import { setTempCookie, getCookie } from "@/core/plugins/bt-cookie.js";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);
export default {
  name: "BthrustGenicTeams",
  data: function data() {
    return {
      updateCount: 0,
      reloadNow: false,
      timeInterval: null,
      timeIntervalLimit: 5000
    };
  },
  components: {
    vueTopprogress: vueTopprogress
  },

  /*updated() {
    this.updateCount = this.updateCount + 1;
    if (this.updateCount <= 10) {
      clearInterval(this.timeInterval);
    }
  },*/
  methods: {
    reloadPage: function reloadPage() {
      window.location.reload();
    },
    monitorReload: function monitorReload() {
      var _this = this;

      clearInterval(_this.timeInterval);
      _this.timeInterval = setInterval(function () {
        var lastLoadTime = getCookie("_btlrld_tmp");

        if (typeof lastLoadTime != "string") {
          _this.reloadNow = true;
        }
      }, _this.timeIntervalLimit);
    }
  },
  mounted: function mounted() {
    this.monitorReload();
    setTempCookie({
      key: "_btlrld_tmp",
      value: moment().format("YYYY-MM-DD HH:mm"),
      time: 60 * 10
    });
    /**
     * this is to override the layout config using saved data from localStorage
     * remove this to use config only from static json (@/core/config/layout.config.json)
     */

    this.$store.dispatch(OVERRIDE_LAYOUT_CONFIG);

    var _this = this;

    _this.$refs.topProgress.start();

    TopBarLoaderEventBus.$on("start:loader", function () {
      _this.$refs.topProgress.decrease(50);

      _this.$refs.topProgress.start();
    });
    TopBarLoaderEventBus.$on("done:loader", function () {
      _this.$refs.topProgress.done();
    });
    TopBarLoaderEventBus.$on("fail:loader", function () {
      _this.$refs.topProgress.fail();
    });
  },
  beforeDestroy: function beforeDestroy() {
    TopBarLoaderEventBus.$off("start:loader");
    TopBarLoaderEventBus.$off("done:loader");
    TopBarLoaderEventBus.$off("fail:loader");
  }
};