import _toConsumableArray from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/toConsumableArray.js";
import _defineProperty from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";
import _typeof from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/typeof.js";

var _actions, _mutations;

import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/es.array.concat.js";
// action types
export var APPEND_BREADCRUM = "appendBreadcrumb"; // mutation types

export var SET_BREADCRUMB = "setBreadcrumb";
export var ADD_BREADCRUMB = "addBreadcrumb";
export default {
  state: {
    breadcrumbs: []
  },
  getters: {
    /**
     * Get list of breadcrumbs for current page
     * @param state
     * @returns {*}
     */
    breadcrumbs: function breadcrumbs(state) {
      return state.breadcrumbs;
    },

    /**
     * Get the page title
     * @param state
     * @returns {*}
     */
    pageTitle: function pageTitle(state) {
      var last = state.breadcrumbs[state.breadcrumbs.length - 1];

      if (last && last.barcode) {
        return last.barcode;
      }

      if (last && last.title) {
        return last.title;
      }
    }
  },
  actions: (_actions = {}, _defineProperty(_actions, SET_BREADCRUMB, function (state, payload) {
    state.commit(SET_BREADCRUMB, payload);
  }), _defineProperty(_actions, ADD_BREADCRUMB, function (state, payload) {
    if (_typeof(payload) === "object") {
      payload.forEach(function (item) {
        return state.commit(APPEND_BREADCRUM, item);
      });
    } else {
      state.commit(APPEND_BREADCRUM, payload);
    }
  }), _actions),
  mutations: (_mutations = {}, _defineProperty(_mutations, APPEND_BREADCRUM, function (state, payload) {
    state.breadcrumbs = [].concat(_toConsumableArray(state.breadcrumbs), [payload]);
  }), _defineProperty(_mutations, SET_BREADCRUMB, function (state, payload) {
    state.breadcrumbs = payload;
  }), _mutations)
};