import _defineProperty from "D:/fsm/acro-harvest/frontend/node_modules/@babel/runtime/helpers/esm/defineProperty.js";

var _actions;

// action types
export var ADD_BODY_CLASSNAME = "addBodyClassName";
export var REMOVE_BODY_CLASSNAME = "removeBodyClassName";
export var ADD_CLASSNAME = "addClassName"; // mutation types

export var SET_CLASSNAME_BY_POSITION = "setClassNameByPosition";
export default {
  state: {
    classes: {}
  },
  getters: {
    getClasses: function getClasses(state) {
      return function (position) {
        if (typeof position !== "undefined") {
          return state.classes[position];
        }

        return state.classes;
      };
    }
  },
  actions: (_actions = {}, _defineProperty(_actions, ADD_BODY_CLASSNAME, function (context, className) {
    document.body.classList.add(className);
  }), _defineProperty(_actions, REMOVE_BODY_CLASSNAME, function (context, className) {
    document.body.classList.remove(className);
  }), _defineProperty(_actions, ADD_CLASSNAME, function (context, payload) {
    context.commit(SET_CLASSNAME_BY_POSITION, payload);
  }), _actions),
  mutations: _defineProperty({}, SET_CLASSNAME_BY_POSITION, function (state, payload) {
    var position = payload.position,
        className = payload.className;

    if (!state.classes[position]) {
      state.classes[position] = [];
    }

    state.classes[position].push(className);
  })
};